import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTransition, animated } from 'react-spring';
import classnames from 'classnames';
import Mousetrap from 'mousetrap';
import { NavDropdownItem, NavItem } from './mainNav';
import useWindowSize from 'hooks/useWindowSize';
import { useUserContext } from 'contexts/userContext';
import { ReactComponent as Logo } from 'images/logo.svg';
import { ReactComponent as Logomark } from 'images/logomark.svg';
import SmartLink from 'components/smartLink';
import styles from './header.module.scss';

function MobileNav({ children, shouldRender, containerRef, className }) {
  const transitions = useTransition(shouldRender, null, {
    config: { mass: 1, tension: 210, friction: 20 },
    from: { opacity: 0, transform: 'translate3d(-100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-100%,0,0)' },
  });

  useEffect(() => {
    if (shouldRender && containerRef.current) containerRef.current.focus();
  }, [containerRef, shouldRender]);

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.nav
          tabIndex={-1}
          ref={containerRef}
          key={key}
          style={props}
          className={classnames(styles.mobile_menu, className)}
        >
          {children}
        </animated.nav>
      )
  );
}

export default function Header({ location }) {
  const windowSize = useWindowSize(100);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const hamburgerRef = useRef();
  const mobileMenuRef = useRef();
  const skipLinkRef = useRef();
  const [showBackToTop, setShowBackToTop] = useState(false);
  const MONOLITH_URL = process.env.MONOLITH_BASE_URL;

  const user = useUserContext().user;
  useEffect(() => {
    if (
      user &&
      user.isLoaded &&
      user.is_authenticated &&
      user?.features?.has_premium_license === 'on'
    ) {
      if (typeof window !== 'undefined') {
        const scriptTag = document.createElement('script');
        scriptTag.setAttribute('type', 'text/javascript');
        scriptTag.setAttribute(
          'src',
          'https://newsela--dev3.sandbox.my.site.com/ESWFormativeMessaging1740676554255/assets/js/bootstrap.min.js'
        );
        scriptTag.onload = () => {
          const initScript = document.createElement('script');
          initScript.setAttribute('type', 'text/javascript');
          initScript.innerHTML = `
          function initEmbeddedMessaging() {
            try {
              embeddedservice_bootstrap.settings.language = 'en_US'; // For example, enter 'en' or 'en-US'

                window.addEventListener("onEmbeddedMessagingReady", () => {
                  console.log("Received the onEmbeddedMessagingReady event…");

                // Send data to Salesforce
                embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({"Website" : "Newsela"});
              });

              embeddedservice_bootstrap.init(
                '00DEa0000097G2r',
                'Messaging_Session',
                'https://newsela--qa.sandbox.my.site.com/ESWMessagingSession1741645695286',
                {
                  scrt2URL: 'https://newsela--qa.sandbox.my.salesforce-scrt.com'
                }
              );
            } catch (err) {
              console.error('Error loading Embedded Messaging: ', err);
            }
          };
          initEmbeddedMessaging();
        `;
          document.head.appendChild(initScript);
        };
        document.head.appendChild(scriptTag);
      }
    }
  }, [user]);

  function logout(e) {
    e.preventDefault();
    fetch(`${MONOLITH_URL}/api/v2/session`, {
      method: 'DELETE',
      credentials: 'include',
    }).then(() => {
      window.location.reload();
    });
  }

  /*
   *   Close mobile menu
   */
  function closeMobileMenu(evt) {
    if (
      evt.key !== 'Escape' &&
      (!mobileMenuRef.current || mobileMenuRef.current.contains(evt.target))
    )
      return;
    setMobileMenuIsOpen(false);
    Mousetrap.unbind(['esc']);
    if (hamburgerRef.current) hamburgerRef.current.focus();
  }

  /*
   *   Toggle mobile menu
   */
  function toggleMobileMenu() {
    setMobileMenuIsOpen(!mobileMenuIsOpen);
    if (!mobileMenuIsOpen) Mousetrap.bind(['esc'], closeMobileMenu);
  }

  /*
   *   On scroll
   */
  const onScroll = useCallback(() => {
    const percentScrolled =
      (window.scrollY + window.innerHeight) / document.body.clientHeight;
    if (percentScrolled > 0.5 && !showBackToTop) {
      setShowBackToTop(true);
    } else if (percentScrolled <= 0.5 && showBackToTop) {
      setShowBackToTop(false);
    }
  }, [showBackToTop]);

  /*
   *   Use Effect
   */
  useEffect(() => {
    window.addEventListener('scroll', onScroll);
  }, [onScroll]);

  useEffect(() => {
    if (mobileMenuIsOpen) {
      document.addEventListener('click', closeMobileMenu);
    } else {
      document.removeEventListener('click', closeMobileMenu);
    }

    return () => document.removeEventListener('click', closeMobileMenu);
  }, [mobileMenuIsOpen]);

  useEffect(() => {
    if (skipLinkRef.current && document.body.classList.contains('keyboard-nav'))
      skipLinkRef.current.focus();
  }, [location, location.href]);

  /*
   *   Nav items
   */
  const navItems = [
    <NavItem
      key='contact'
      to={'/contact'}
      label={'Contact'}
      accordion={windowSize.width < 1001}
    />,
  ];

  if (user.isLoaded) {
    navItems.push(
      <NavItem
        key='community'
        to='https://community.newsela.com'
        label={'Community'}
        accordion={windowSize.width < 1001}
      />
    );
    navItems.push(
      <NavItem
        key='educator-center'
        to={`${MONOLITH_URL}/educator-center`}
        label={'Professional Learning'}
        accordion={windowSize.width < 1001}
      />
    );
  }

  navItems.push(
    <NavItem
      key='support-center'
      to='/'
      label='Support Center'
      accordion={windowSize.width < 1001}
    />
  );

  /*
   *   Return
   */
  return (
    <>
      <a
        ref={skipLinkRef}
        href='#main-content-anchor'
        className={styles.skip_link}
      >
        Skip to Content
      </a>

      <header className={styles.container}>
        <div className={styles.inner}>
          <nav className={styles.menu} role='menu'>
            {windowSize.width < 1001 && (
              <button
                aria-label='Hamburger Menu'
                ref={hamburgerRef}
                className={classnames(styles.hamburger, {
                  [styles.active]: mobileMenuIsOpen,
                })}
                onClick={toggleMobileMenu}
              >
                <div className={styles.hamburger__patty} />
              </button>
            )}
            <SmartLink
              aria-label='Newsela logo, link to homepage'
              to={`${MONOLITH_URL}/`}
              className={styles.logo}
            >
              <Logo />
              <Logomark />
            </SmartLink>
            {windowSize.width > 1000 && navItems}
          </nav>
          {user.isLoaded && user.is_authenticated && (
            <div className={styles.right_menu}>
              <NavItem
                key={'user'}
                to={`${MONOLITH_URL}/settings`}
                label={`${user.first_name} ${user.last_name}`}
                accordion={windowSize.width < 1001}
                forceClose={location.href}
              >
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '12px',
                    marginRight: '16px',
                    color: '#767676',
                    height: '40px',
                    minWidth: '180px',
                    padding: '0 14px',
                    cursor: 'default',
                  }}
                >
                  {user.email}
                </div>
                <NavDropdownItem onClick={(e) => logout(e)}>
                  Sign Out
                </NavDropdownItem>
              </NavItem>
            </div>
          )}
          {user.isLoaded && !user.is_authenticated && (
            <div className={styles.right_menu}>
              <a href={`${MONOLITH_URL}/join`} className={styles.sign_up}>
                Join Now
              </a>
              <a
                href={`${MONOLITH_URL}/signin?next=${window.location.href}`}
                className={styles.sign_in}
              >
                Sign In
              </a>
            </div>
          )}
        </div>
      </header>
      <MobileNav
        containerRef={mobileMenuRef}
        shouldRender={windowSize.width < 1001 && mobileMenuIsOpen}
      >
        {navItems}
      </MobileNav>
      <div id='main-content-anchor' aria-label='Main page content'></div>
      <a
        tabIndex='-1'
        href='#main-content-anchor'
        className={classnames(styles.back_to_top, {
          [styles.visible]: showBackToTop,
        })}
      >
        Back to top
      </a>
    </>
  );
}
